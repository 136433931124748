/* eslint-disable @typescript-eslint/ban-ts-comment */

import clsx from "clsx";
import styled, { css } from "styled-components";

import * as Logos from "~/components/icons/Logo";
import { Text } from "~/components/Text";
import { from } from "~/styles/theme";
import * as Bulma from "~/types/bulma";

export type Inverted = { inverted?: boolean };

export const BORDER = {
  LEFT: css`
    border-left: solid 1px ${(props) => props.theme.colors.lightGray};
  `,
  RIGHT: css`
    border-right: solid 1px ${(props) => props.theme.colors.lightGray};
  `,
  TOP: css`
    border-top: solid 1px ${(props) => props.theme.colors.lightGray};
  `,
  BOTTOM: css`
    border-bottom: solid 1px ${(props) => props.theme.colors.lightGray};
  `,
};

export type FixedProps = { fixed?: "top" | "bottom" };
export type ContainerProps = { spaced?: boolean; transparent?: boolean; css?: any } & FixedProps & Bulma.Colorable;
const CONTAINER_TRANSPARENT = css`
  &&& {
    background-color: transparent;
  }
`;
const CONTAINER_FIXED_TOP = css`
  &&& {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
  }
`;
const CONTAINER_FIXED_BOTTOM = css`
  &&& {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 30;
  }
`;
export const Container = styled.nav.attrs<ContainerProps>((props) => ({
  className: clsx({
    [`navbar`]: true,
    [`is-transparent`]: props.transparent,
    [`is-spaced`]: props.spaced,
    [`is-${props.color}`]: Boolean(props.color),
  }),
  [`role`]: `navigation`,
  [`aria-label`]: `main navigation`,
}))<ContainerProps>`
  ${(props) => props.transparent && CONTAINER_TRANSPARENT}
  ${(props) => props.fixed === "top" && CONTAINER_FIXED_TOP}
  ${(props) => props.fixed === "bottom" && CONTAINER_FIXED_BOTTOM}
`;

export const ContentBrand = styled.div.attrs({ className: clsx("navbar-brand") })<{ css?: any }>``;
export const ContentMenu = styled.div.attrs<Bulma.Activable>((props) => ({
  className: clsx({
    [`navbar-menu`]: true,
    [`is-active`]: props.active,
  }),
}))<Bulma.Activable>``;

export function Logo(props: React.ComponentProps<typeof LogoContainer>) {
  return (
    <LogoContainer {...props}>
      <Logos.Logo style={{ width: "100%", height: "100%" }} />
    </LogoContainer>
  );
}
const LogoContainer = styled.div<Inverted>`
  background-color: transparent;
  width: ${1100 * 0.1}px;
  height: ${235 * 0.1}px;
  ${(props) =>
    props.inverted &&
    css`
      filter: brightness(0) invert(1);
    `}
`;

export function Hamburger({ showMenu, onToggle, ...props }: any) {
  return (
    <a
      role="button"
      className={clsx({
        [`navbar-burger`]: true,
        [`burger`]: true,
        [`is-active`]: showMenu,
      })}
      aria-label="menu"
      aria-expanded={showMenu}
      onClick={onToggle}
      {...props}
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  );
}

export type ItemProps = Bulma.Text & Bulma.Activable & { expanded?: boolean; tab?: boolean };
export const Item = styled(Text as "a").attrs<ItemProps>((props) => ({
  className: clsx({
    [`navbar-item`]: true,
    [`is-tab`]: props.tab,
    [`is-active`]: props.active,
    [`is-expanded`]: props.expanded,
  }),
}))<ItemProps>`
  ${from.desktop(css`
    padding: calc(0.5rem) calc(0.75rem * 2);

    &.is-active {
      box-shadow: inset 0 -6px 0 0 black;
    }
  `)}
`;
Item.defaultProps = {
  // @ts-ignore
  as: "a",
  size: 7,
  weight: "bolder",
  tab: false,
};

/**
 * @example
 * <Navbar.Dropdown>
 *   <Navbar.DropdownLink>More</Navbar.DropdownLink>
 *   <Navbar.DropdownContent>
 *     <Navbar.Item>Item 1</Navbar.Item>
 *     <Navbar.Item>Item 2</Navbar.Item>
 *     <Navbar.DropdownDivider />
 *     <Navbar.Item>Item 3</Navbar.Item>
 *   </Navbar.DropdownContent>
 * </Navbar.Dropdown>
 */
export const Dropdown = styled(Item).attrs({
  as: "div",
  className: clsx({
    [`is-hoverable`]: true,
    [`has-dropdown`]: true,
  }),
})``;
/**
 * Hoverable button with left arrow
 */
export const DropdownLink = styled.a.attrs({
  className: clsx({
    [`navbar-link`]: true,
  }),
})``;
export const DropdownContent = styled.div.attrs<{ right?: boolean }>((props) => ({
  className: clsx({
    [`navbar-dropdown`]: true,
    [`is-right`]: props.right,
  }),
}))<{ right?: boolean }>``;
export const DropdownDivider = styled.hr.attrs({
  className: clsx({
    [`navbar-divider`]: true,
  }),
})``;

export const Start = styled.div.attrs({ className: clsx("navbar-start") })``;
export const End = styled.div.attrs({ className: clsx("navbar-end") })``;
