import styled from "styled-components";

export const Viewport = styled.div`
  /* Footer height */
  /* padding-bottom: calc(calc(0.75rem * 14) + calc(0.75rem * 6)); */
  padding-bottom: calc(0.75rem * 14);
`;

export const Page = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
`;
