/**
 * https://github.com/jgthms/bulma/blob/master/sass/elements/container.sass
 * https://github.com/jgthms/bulma/blob/master/sass/grid/columns.sass
 */

import styled, { css, DefaultTheme } from "styled-components";

import { from, ALIGNMENT } from "~/styles/theme";
import * as Bulma from "~/types/bulma";

export type SizeOption = "mobile" | "tablet" | "desktop" | "widescreen" | "fullhd";

export type ContainerProps = {
  fluidness?: "fluid" | "fullhd" | "widescreen" | "desktop" | "tablet" | "mobile";
  gapless?: boolean | null;
};

export const Container = styled.div<ContainerProps>`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;

  ${from.desktop(css`
    max-width: ${(props) => `calc(${props.theme.breakpoints.desktop} - ${props.theme.container.offset})`};
  `)}
  ${from.widescreen(css`
    max-width: ${(props) => `calc(${props.theme.breakpoints.widescreen} - ${props.theme.container.offset})`};
  `)}
  ${from.fullhd(css`
    max-width: ${(props) => `calc(${props.theme.breakpoints.fullhd} - ${props.theme.container.offset})`};
  `)}

  ${(props) =>
    props.fluidness === "mobile" &&
    css`
      &&& {
        max-width: ${`calc(600px)`};
        width: 100%;
      }
    `}

  ${(props) =>
    props.fluidness === "tablet" &&
    css`
      &&& {
        max-width: ${`calc(${props.theme.breakpoints.tablet} - ${props.theme.container.offset})`};
        width: 100%;
      }
    `}

  ${(props) =>
    props.fluidness === "desktop" &&
    css`
      &&& {
        max-width: ${`calc(${props.theme.breakpoints.desktop} - ${props.theme.container.offset})`};
        width: 100%;
      }
    `}

  ${(props) =>
    props.fluidness === "widescreen" &&
    css`
      &&& {
        max-width: ${`calc(${props.theme.breakpoints.widescreen} - ${props.theme.container.offset})`};
        width: 100%;
      }
    `}

    ${(props) =>
    props.fluidness === "fullhd" &&
    css`
      &&& {
        max-width: ${`calc(${props.theme.breakpoints.fullhd} - ${props.theme.container.offset})`};
        width: 100%;
      }
    `}

    ${(props) =>
    props.fluidness === "fluid" &&
    css`
      &&& {
        max-width: none;
        padding-left: ${props.theme.gap};
        padding-right: ${props.theme.gap};
        width: 100%;
      }
    `}
    ${(props) =>
    props.gapless &&
    css`
      &&& {
        padding-left: 0;
        padding-right: 0;
      }
    `}
`;

export type ColumnsProps = Bulma.Alignment & {
  multiline: boolean;
  mobile?: boolean | null;
  valign?: Bulma.Alignment["align"];
  gapless?: boolean | null;
};

export const Columns = styled.div<ColumnsProps>`
  margin-left: -${gap};
  margin-right: -${gap};
  margin-top: -${gap};
  &:last-child {
    margin-bottom: -${gap};
  }
  &:not(:last-child) {
    margin-bottom: calc(1.5rem - ${gap});
  }

  ${(props) =>
    props.mobile &&
    css`
      display: flex;
    `}

  ${(props) =>
    props.multiline &&
    css`
      flex-wrap: wrap;
    `}

  ${(props) =>
    props.align &&
    css`
      justify-content: ${ALIGNMENT(props)};
    `}

  ${(props) =>
    props.valign &&
    css`
      align-items: ${ALIGNMENT({ align: props.valign })};
    `}

  ${(props) =>
    props.gapless &&
    css`
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;

      & > ${Column} {
        margin: 0;
        padding: 0 !important;
      }
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    `}
`;
Columns.defaultProps = {
  mobile: true,
};

export type ColumnSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number | null;
export type ColumnProps = {
  mobile?: ColumnSize;
  mobileOffset?: ColumnSize;
  tablet?: ColumnSize;
  tabletOffset?: ColumnSize;
  desktop?: ColumnSize;
  desktopOffset?: ColumnSize;
  widescreen?: ColumnSize;
  widescreenOffset?: ColumnSize;
  fullhd?: ColumnSize;
  fullhdOffset?: ColumnSize;

  narrow?: SizeOption | boolean;
  flex?: SizeOption | boolean;

  // FIXME: Temporal workaround
  css?: any;
};

const COLUMN_NARROW = css`
  &&& {
    flex: none;
    width: auto;
  }
`;
const COLUMN_FLEX = css`
  &&& {
    flex: 1;
  }
`;

/**
 * @deprecated
 * Please ask yourself why you are using the Grid + Grid.Column pattern
 * Probably you can achieve better results with plain Flex and css
 * The usage of Grid is legacy and probably will be removed
 * */
export const Column = styled.div<ColumnProps>`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${gap};

  ${({ mobile, mobileOffset }) => responsiveness("mobile", mobile, mobileOffset)};
  ${({ tablet, tabletOffset }) => responsiveness("tablet", tablet, tabletOffset)};
  ${({ desktop, desktopOffset }) => responsiveness("desktop", desktop, desktopOffset)};
  ${({ widescreen, widescreenOffset }) => responsiveness("widescreen", widescreen, widescreenOffset)};
  ${({ fullhd, fullhdOffset }) => responsiveness("fullhd", fullhd, fullhdOffset)};

  ${(props) => props.narrow && (props.narrow === true ? COLUMN_NARROW : from[props.narrow](COLUMN_NARROW))}
  ${(props) => props.flex && (props.flex === true ? COLUMN_FLEX : from[props.flex](COLUMN_FLEX))}
`;

function gap(props: { theme: DefaultTheme }) {
  return props.theme.gap;
}

const NONE = css`
  display: none;
`;

function responsiveness(size: SizeOption, value?: ColumnSize, valueOffset?: ColumnSize) {
  if (value === undefined) {
    return null;
  } else if (value === 0 || value === null) {
    return from[size](NONE);
  } else {
    return from[size](css`
      display: block;
      flex: none;
      width: ${percentage(value)};
      margin-left: ${percentage(valueOffset || 0)};
    `);
  }
}

function percentage(val: number, of = 12) {
  return ((val / of) * 100).toFixed(3) + "%";
}
